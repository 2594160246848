import React from "react";

const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Kimteng Ley,</span> a Full-Stack Developer
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              I'm a graduated Student of Kirirom Institute of Technology, where
              I majored in Software Engineering. As a full-stack developer, I bring
              technical expertise, intelligence, and responsibility to each project.
              Friendly and approachable, I help others solve problems while delivering
              high-quality work with dedication and focus. My commitment to projects
              is evident in the results achieved.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              With my background in software engineering, I deliver functional
              and visually appealing solutions to complex software development
              projects. Skilled in various programming languages and technologies,
              I work efficiently and effectively. Dedicated to delivering
              top-notch results in every project.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Kimteng LEY
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:leykimteng@gmail.com">leykimteng@gmail.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>23
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Phnom Penh,
                  Cambodia
                </li>
              </ul>
              <a
                href="/kimteng-LEY-CV.pdf"
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-8 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>5</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-8 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>25</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-8 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>40</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            {/*<div className="col-6 col-md-3">*/}
            {/*  <div className="featured-box text-center">*/}
            {/*    <h4*/}
            {/*      className={*/}
            {/*        "text-12  mb-0 " +*/}
            {/*        (darkTheme ? "text-white-50" : "text-muted")*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <span>3</span>*/}
            {/*    </h4>*/}
            {/*    <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>*/}
            {/*      Get Awards*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
