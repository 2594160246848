import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <h3 className={"mb-3 " + (darkTheme ? "text-white" : "")}>
              Terms of Use
            </h3>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part I – Acceptance of Terms
            </h5>
            <p>
              By accessing and using this portfolio website, you agree to be
              bound by these Terms of Use. If you do not agree to these terms,
              do not use the website.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part II – Copyright Notice
            </h5>
            <p>
              The content on this website, including but not limited to text,
              graphics, logos, images, and software, is the property of
              Kimteng LEY and is protected by copyright laws. Any unauthorized
              use of this content is strictly prohibited.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part III – Limitation of Liability
            </h5>
            <p>
              Kimteng Ley makes no representations or warranties of any kind,
              express or implied, about the completeness, accuracy, reliability,
              suitability or availability with respect to the website or the
              information, products, services, or related graphics contained
              on the website for any purpose.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part IV – Changes to Terms of Use
            </h5>
            <p>
              Kimteng Ley reserves the right to modify these Terms of Use at
              any time without notice. Your continued use of the website after
              any changes are made constitutes your acceptance of the updated
              terms.
            </p>

            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              Privacy Policy
            </h3>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              1. Information Collection and Use
            </h5>
            <p>
              Kimteng Ley collects personal
              information when you contact him through the website. This
              information is used for the sole purpose of responding to your
              inquiry.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              2. Data Security
            </h5>
            <p>
              Kimteng Ley takes appropriate security measures
              to protect against unauthorized access to or unauthorized
              alteration, disclosure or destruction of data.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              3. Data Retention
            </h5>
            <p>
              Kimteng Ley will retain your personal information
              only for as long as necessary to fulfill the purpose for which
              it was collected.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              4. Changes to Privacy Policy
            </h5>
            <p>
              Kimteng Ley reserves the right to modify
              this Privacy Policy at any time without notice. Your continued use
              of the website after any changes are made constitutes your acceptance
              of the updated policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
