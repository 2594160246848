import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <h5 className={"mb-3 " + (darkTheme ? "text-white" : "")}>
              Copyright Notice:
            </h5>
            <p>
              The content on this portfolio website, including but not limited to
              text, graphics, logos, images, and software, is the property of
              Kimteng Ley and is protected by copyright laws. Any unauthorized use
              of this content, including reproduction, modification, distribution,
              or republication, without the prior written consent of Kimteng Ley
              is strictly prohibited.
            </p>

            <h5 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Disclaimer:
            </h5>
            <p>
              The information on this portfolio website is provided "as is" without
              warranty of any kind, either express or implied, including but not
              limited to the implied warranties of merchantability, fitness for a
              particular purpose, or non-infringement. Kimteng Ley makes no
              representations or warranties of any kind, express or implied, about
              the completeness, accuracy, reliability, suitability or availability
              with respect to the website or the information, products, services,
              or related graphics contained on the website for any purpose.
            </p>
            <p>
              In no event will Kimteng Ley be liable for any loss or damage including
              without limitation, indirect or consequential loss or damage, or any
              loss or damage whatsoever arising from loss of data or profits arising
              out of, or in connection with, the use of this portfolio website.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
